<template>
  <header class="w-100">
    <b-container fluid
      class="w-100 border-bottom border-right border-right-light border-right-line bg-light bg-background d-flex align-items-center"
      style="height: 83.19px;">
      <div class="d-flex justify-content-between w-100 align-items-center">
        <h1 class="h6 fw-bold-700 mb-0 w-100" :class="className">
          <slot />
        </h1>
        <slot name="action" />
      </div>
    </b-container>
    <div v-if="listVisiblePathMenu.includes($route.name)">
      <MasterMenus />
    </div>
  </header>
</template>

<script>
import { BContainer } from 'bootstrap-vue'
import MasterMenus from '@/components/Master/Menus.vue'

export default {
  components: {
    BContainer,
    MasterMenus
  },
  props: {
    className: {
      type: String,
      default: 'size16 ml-2',
    },
  },
  data() {
    return {
      listVisiblePathMenu: ['master-toko.index', 'master-billing.index', 'master-gudang.index', 'master-users.index', 'master-sales.index', 'master-cabang.index', 'master-rekening.index', 'ads.index']
    }
  }
}
</script>
