<template>
    <b-row class="mb-3 mt-2">
        <b-col cols="12" md="8" lg="6">
            <b-button-group>
                <b-button v-for="(item, index) in menus" :id="`button__filter__${index}`"
                    :key="`${item.title}-${index}`" class="filter--button"
                    :class="{ 'active': item.route == $route.name }" @click="() => $router.push({ name: item.route })">
                    {{ item.title }}
                </b-button>
            </b-button-group>
        </b-col>
    </b-row>
</template>

<script>
import HeaderPage from "@/components/HeaderPage.vue";
import { BContainer, BRow, BCol, BButtonGroup, BButton } from "bootstrap-vue";
import { masterMenuFiltered } from '@/auth/utils'

export default {
    title() {
        return "Master";
    },
    components: {
        HeaderPage,
        BContainer,
        BRow,
        BCol,
        BButton,
        BButtonGroup
    },
    setup() {
        return {
            masterMenuFiltered
        }
    },
    data() {
        return {
            menus: masterMenuFiltered(),
        };
    },
    methods: {
    },
    mounted() {
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.filter--button {
    border: none !important;
    color: $light--2 !important;
    background-color: transparent !important;
    border-color: transparent !important;
    white-space: nowrap;

    &.active {
        color: $secondary !important;
        position: relative;
        background-color: transparent !important;

        &::after {
            content: "";
            position: absolute;
            bottom: -48%;
            left: 0;
            width: 50%;
            margin-left: 25%;
            border-radius: 4px 4px 0px 0px;
            border: 2px solid $secondary;
        }
    }

    &:hover,
    &:focus {
        box-shadow: none !important;
    }
}
</style>
